<template>
  <div class="fill-height">
    <v-container fluid class="container-main">
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field v-model="targetUrl" label="Target Url"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="postBody" label="Post Body"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn @click="get()">GET</v-btn>
          <v-btn @click="post()">Post</v-btn>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="output"></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Tester",
  data: () => ({
    targetUrl: "",
    postBody: "",
    output: ""
  }),
  components: {},
  mounted() {},
  methods: {
    get() {
      // make get
      this.output = "";
      this.$http.get(this.targetUrl).then(result => {
        console.log(result);
        this.output = JSON.stringify(result.data);
      });
    },
    post() {
      // make post
      this.output = "";
      this.$http.post(this.targetUrl, JSON.parse(this.postBody)).then(result => {
        console.log(result);
        this.output = JSON.stringify(result.data);
      });
    }
  },
  computed: {}
};
</script>
